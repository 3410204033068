* {
  box-sizing: border-box;
  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  .ld-navbar {
    padding: 2rem 20px 1rem 20px;
    background: #141414;
    position: fixed;
    width: 100%;
    z-index: 20;
    .container {
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-hambuger {
        width: 24px;
        height: 24px;
        cursor: pointer;
        @media (min-width: 640px) {
          margin-right: 10px;
        }
      }
      .logo {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 700;
        display: flex;
        @media (min-width: 640px) {
          flex: 1 1;
        }
        a {
          display: flex;
          text-decoration: none;
        }
        p {
          color: #fff;
        }
        // p.sec {
        //   color: #b75cff;
        // }
      }
      .case-one,
      .case-network {
        display: none;
        @media (min-width: 640px) {
          display: block;
          margin-left: 10px;
        }
      }
      .case-two {
        display: block;
        cursor: pointer;
        width: 24px;
        height: 24px;
        @media (min-width: 640px) {
          display: none;
        }
      }
      .fade {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 20;
        padding-top: 5rem;
        padding-bottom: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
        background: #000000e6;
        .mobile-menu {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
      }
      .sidebar {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 640px) {
          flex-direction: row;
        }
        .close {
          position: absolute;
          right: 30px;
          top: 30px;
          cursor: pointer;
          color: #fff;
          @media (min-width: 640px) {
            display: none;
          }
        }
        a {
          margin-right: 0;
          margin-left: 0;
          margin-bottom: 0.75rem;
          color: #fff;
          text-decoration: none;
          font-size: 0.75rem;
          @media (min-width: 640px) {
            margin-bottom: 0;
            margin-right: 1.75rem;
            margin-left: 1.75rem;
          }
        }
        .art-app-btn {
          margin-bottom: 0.75rem;
          @media (min-width: 640px) {
            margin-bottom: 0;
            margin-right: 1.75rem;
          }
        }
      }
    }
    .choose-network {
      .nav-box {
        .heading {
          @media (max-width: 640px) {
            font-size: 18px;
          }
        }
        .content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          @media (max-width: 640px) {
            gap: 10px;
          }
          .network-box {
            cursor: pointer;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            border: 1px solid #b6e77e;
            border-radius: 5px;
            @media (max-width: 640px) {
              padding: 10px;
              gap: 10px;
              justify-content: space-between;
            }
            img {
              width: 50px;
              height: 50px;
              @media (max-width: 640px) {
                width: 30px;
                height: 30px;
              }
            }
            .chain_name {
              font-size: 18px;
              font-weight: 600;
              color: #fff;
              @media (max-width: 640px) {
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
  .ld-navbar.presale-back {
    background: #fff;
    .container {
      .left-hambuger,
      .case-two {
        background-color: #000;
      }
      .logo {
        p {
          color: #000;
        }
      }
      .case-one {
        .sidebar {
          button {
            background: #000;
          }
        }
      }
    }
  }

  .ld-footer {
    padding-top: 2rem;
    padding-bottom: 1rem;
    background: #181a20;
    position: relative;
    .nav-container {
      display: flex;
      flex-direction: column;
      .link-group {
        margin: 0 auto;
        .sidebar {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          a {
            text-decoration: none;
            color: #fff;
            padding: 0 10px;
            border-right: 1px solid #fff;
            @media (max-width: 640px) {
              padding: 0 7px;
            }
          }
          a:last-child {
            border-right: none;
          }
        }
      }
      .social-group {
        margin: 20px auto;
        display: flex;

        .icon {
          padding: 10px;
          width: 24px;
          height: 24px;
          background: gray;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 10px;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .copyright {
        text-align: center;
        h4 {
          color: #fff;
        }
      }
    }
  }

  .button-link {
    border: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: center;
    border-radius: 0.75rem;
    background: #ffffff1a;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    @media (max-width: 640px) {
      font-size: 0.75rem;
    }
  }
  .btn_default {
    color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .btn_primary {
    color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background: #1abc13;
  }
  .btn_primary_outline {
    color: #1abc13;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #1abc13 !important;
  }
  .btn_primary_outline:hover {
    color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background: #1abc13;
  }

  .card {
    border-radius: 20px;
    position: relative;
    width: 100%;
    height: auto;
    box-sizing: border-box;
  }

  .modal {
    position: fixed;
    width: 80%;
    height: auto;
    max-width: 700px;
    max-height: 90%;
    overflow: auto;
    background-image: radial-gradient(at top left, #2d2d2d 0%, #191919 100%);
    border-radius: 20px;
    padding: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 21;
   
    @media (max-width: 640px) {
      padding: 10px;
    }
    .modal-container {
      position: relative;
      width: 100%;
      .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        position: absolute;
        border-radius: 10px;
      }
      .overlay.show-overlay {
        display: block;
      }
      .overlay.hide-overlay {
        display: none;
      }
      @keyframes innerSpinner {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(22.5deg);
        }
        100% {
          transform: rotate(45deg);
        }
      }
      .inner-spinner > div {
        transform-origin: 75px 75px;
        animation: innerSpinner 0.2s infinite linear;
      }
      .inner-spinner > div div {
        position: absolute;
        width: 15px;
        height: 90px;
        background: #6c5ecb;
        left: 75px;
        top: 75px;
        transform: translate(-50%, -50%);
      }
      .inner-spinner > div div:nth-child(1) {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      .inner-spinner > div div:nth-child(6) {
        width: 35px;
        height: 35px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 50%;
      }
      .inner-spinner > div div:nth-child(3) {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      .inner-spinner > div div:nth-child(4) {
        transform: translate(-50%, -50%) rotate(90deg);
      }
      .inner-spinner > div div:nth-child(5) {
        transform: translate(-50%, -50%) rotate(135deg);
      }
      .loading-spinner {
        width: 150px;
        height: 150px;
        display: inline-block;
        overflow: hidden;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .inner-spinner {
        width: 100%;
        height: 100%;
        position: relative;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0; /* see note above */
      }
      .inner-spinner div {
        box-sizing: content-box;
      }
      .close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        color: #fff;
      }
      .heading {
        text-align: center;
        color: #fff;
        padding: 10px;
        @media (max-width: 640px) {
          font-size: 18px;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        padding: 10px;
        .column {
          display: flex;
          flex-direction: column;
          padding: 10px;
          label {
            color: #fff;
            margin-bottom: 10px;
          }
          span {
            color: #fff;
            margin-left: 10px;
          }
          .cursor-pointer {
            cursor: pointer;
          }
        }
        .row {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 10px;
          @media (max-width: 640px) {
            flex-direction: column;
          }
          .cursor-pointer {
            cursor: pointer;
          }
          label {
            width: 30%;
            @media (max-width: 640px) {
              width: 100%;
              margin-bottom: 5px;
            }
            color: #fff;
          }
          span {
            color: #fff;
            width: 60%;
            @media (max-width: 640px) {
              width: 100%;
            }
          }
          .input-group {
            display: flex;
            flex-direction: column;
            width: 60%;
            @media (max-width: 640px) {
              width: 100%;
            }
            .input {
              height: 35px;
              border: none;
              outline: none;
              padding-left: 20px;
              box-sizing: border-box;
            }
            .invalid-feedback {
              color: red;
              margin-top: 5px;
            }
          }
          .checkbox-group {
            width: 60%;
            height: 30px;
            display: flex;
            align-items: center;
            .checkbox {
              border: none;
              outline: none;
            }
          }
        }
      }
      .footer {
        padding: 10px;
        display: flex;
        justify-content: space-evenly;
        button {
          font-size: 18px;
          min-width: 100px;
          @media (max-width: 640px) {
            font-size: 14px;
            min-width: 80px;
          }
        }
      }
    }
  }
  .modal.show-modal {
    display: block;
  }
  .modal.hide-modal {
    display: none;
  }
  .white-modal {
    background-image: radial-gradient(at top left, #fff 0%, #f1f1f1 100%);
    .modal-container {
      .overlay {
        background-color: rgba(0, 0, 0, 0.2);
      }
      .close {
        color: #000;
      }
      .heading {
        color: #000;
        @media (max-width: 640px) {
          font-size: 18px;
        }
        font-size: 32px;
      }
      .content {
        @media (max-width: 640px) {
          padding: 0;
        }
      }
    }
  }

  .ps-detail > .container > .left,
  .white-modal > .modal-container {
    .content {
      .column {
        @media (max-width: 640px) {
          padding: 15px 0;
        }
        > .row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          padding: 8px 0;
          span {
            color: #000;
            margin: 0;
            word-break: break-all;
            font-size: 16px;
            @media (max-width: 640px) {
              font-size: 12px;
            }
          }
          .address {
            cursor: pointer;
          }
        }
      }
      .more-view {
        display: flex;
        gap: 50px;
        padding: 10px;
        @media (max-width: 640px) {
          flex-direction: column;
          padding: 15px 0;
          gap: 10px;
        }
        .tokenLogo {
          background-position: center;
          background-size: cover;
          @media (max-width: 640px) {
            width: 50px;
            margin: 0 auto;
          }
          img {
            border-radius: 50%;
            width: 50px;
            height: 50px;
          }
        }
        .tokenMore {
          display: flex;
          flex-direction: column;
          @media (max-width: 640px) {
            width: 100%;
          }
          .tokenName {
            font-size: 24px;
            font-weight: bold;
            line-height: 100%;
            padding-bottom: 5px;
            @media (max-width: 640px) {
              text-align: center;
            }
          }
          .tokenLinks {
            display: flex;
            padding: 10px 0;
            gap: 20px;
            @media (max-width: 640px) {
              justify-content: space-between;
            }
            .social-icon {
              a {
                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
          .tokenDesc {
            padding: 10px 0;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}

.landing {
  .nft-item {
    padding: 1.25rem;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    background: #ffffff1a;
    .out-image {
      overflow: hidden;
      position: relative;
      margin-bottom: 0.75rem;
      width: 100%;
      border-radius: 0.5rem;
      img {
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    .info {
      display: flex;
      margin-bottom: 1.25rem;
      font-weight: 700;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 640px) {
        font-size: 0.75rem;
      }
    }
    .buy-now {
      width: 100%;
    }
  }
  .nft-item:hover {
    background: #ffffff66;
  }

  .container {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }

  .nav-container {
    @media (max-width: 640px) {
      padding: 0px 20px;
    }
  }

  .ld-hero {
    background-color: #020710;
    background-image: url(https://toka.b-cdn.net/wp-content/uploads/2022/04/k45jcn.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 80px;
    .elementor-background-overlay {
      background-image: url(https://toka.b-cdn.net/wp-content/uploads/2022/04/rgkj.png);
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 1;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 1;
    }
    .nav-container {
      display: flex;
      flex-direction: column;
      .header {
        margin: 100px 0px 20px 0px;
        text-align: center;
        color: #ffffff;
        font-family: "Syne", Sans-serif;
        font-size: 70px;
        font-weight: 700;
        line-height: 1.2em;
        letter-spacing: 0px;
        @media (max-width: 640px) {
          font-size: 26px;
          margin: 40px 0px 20px 0px;
        }
      }
      .content {
        text-align: center;
        z-index: 10;
        position: relative;
        button.download {
          border-radius: 10px 10px 10px 10px;
          font-size: 18px;
          padding: 15px 35px 15px 35px;
          background: #6c5ecb;
          margin-bottom: 20px;
        }
        .rotate-widget {
          position: absolute;
          text-align: center;
          width: auto;
          max-width: auto;
          bottom: 0px;
          right: 0;
          left: 0;
          z-index: -1;
          img {
            width: 100%;
            max-width: 517px;
          }
        }
        .phone {
          display: flex;
          justify-content: center;
          z-index: 10;
          @media (max-width: 640px) {
            margin: 40px 0 0 0;
          }
          img {
            max-width: 500px;
            width: 100%;
          }
        }
      }
    }
  }

  .ld-sponsor {
    background-color: #141414;
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: #dddee178;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    z-index: 0;
    .nav-container {
      .brand-list {
        padding: 40px 10px 40px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          height: auto;
          width: 10%;
          max-width: 100%;
          border: none;
          border-radius: 0;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
      }
    }
  }

  .ld-about {
    background-color: #141414;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 80px 0px 80px 0px;
    position: relative;
    .elementor-background-overlay {
      background-color: #141414;
      background-image: url(https://toka.b-cdn.net/wp-content/uploads/2022/04/knvkfv.png);
      background-position: 98% 10%;
      background-repeat: no-repeat;
      background-size: 133px auto;
      opacity: 1;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 0;
      @media (max-width: 640px) {
        background-position: 98% 5%;
        background-size: 69px auto;
      }
    }
    .nav-container {
      display: flex;
      align-items: center;
      @media (max-width: 640px) {
        flex-direction: column;
      }
      .img-group {
        width: 50%;
        @media (max-width: 640px) {
          width: 100%;
        }
        .line-back {
          display: flex;
          align-content: center;
          align-items: center;
          margin: 10px 0px 0px 10px;
          padding: 10px;
          background-image: url(https://toka.b-cdn.net/wp-content/uploads/2022/04/kgvnrgk.png);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          @media (max-width: 640px) {
            margin: 0;
          }
          .star {
            position: absolute;
            left: 2%;
            top: 0;
            width: 150px;
            @media (max-width: 640px) {
              width: 70px;
            }
          }
          .man {
            width: 100%;
          }
        }
      }
      .description {
        z-index: 1;
        width: 50%;
        @media (max-width: 640px) {
          width: 100%;
        }
        .nav-desc {
          margin: 0px 0px 0px 40px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-content: center;
          @media (max-width: 640px) {
            margin: 0;
          }
          .heading {
            margin: 0px 0px 10px 0px;
            color: #f5c06d;
            font-family: "Inter", Sans-serif;
            font-size: 16px;
            font-weight: 800;
            text-transform: uppercase;
            line-height: 1.3em;
            letter-spacing: 1px;
            @media (max-width: 640px) {
              text-align: center;
            }
          }
          .content {
            text-align: left;
            color: #ffffff;
            font-family: "Syne", Sans-serif;
            font-size: 48px;
            font-weight: 600;
            line-height: 1.1em;
            letter-spacing: 0px;
            padding: 0px 0px 20px 0px;
            @media (max-width: 640px) {
              font-size: 26px;
              text-align: center;
            }
          }
          .desc {
            padding: 0px 0px 20px 0px;
            color: #a4a9ac;
            font-family: "DM Sans", Sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.9em;
            @media (max-width: 640px) {
              text-align: center;
              font-size: 16px;
            }
          }
          .link {
            margin: 10px 0px 0px 0px;
            @media (max-width: 640px) {
              text-align: center;
            }
            a {
              font-family: "DM Sans", Sans-serif;
              font-size: 18px;
              font-weight: 700;
              fill: #f5c06d;
              color: #f5c06d;
              background-color: #e0e7fa00;
              border-style: solid;
              border-width: 0px 0px 2px 0px;
              border-color: #f5c06d;
              border-radius: 0px 0px 0px 0px;
              box-shadow: 0px 0px 0px 0pxrgba
                (20.999999999999982, 92.00000000000011, 231, 0.43);
              padding: 0px 0px 7px 0px;
              text-decoration: none;
              .icon {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }

  .ld-portfolio {
    background-color: #141414;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 20px 0px 80px 0px;
    position: relative;
    .elementor-background-overlay {
      background-color: #141414;
      background-image: url(https://toka.b-cdn.net/wp-content/uploads/2022/04/kgvnrgk.png);
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: 40% auto;
      opacity: 1;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      @media (max-width: 640px) {
        background-position: center center;
        background-size: 40% auto;
        opacity: 1;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      }
    }
    .nav-container {
      display: flex;
      .full {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        padding: 10px;
        width: 100%;
        position: relative;
        @media (max-width: 640px) {
          padding: 0;
        }
        .top {
          max-width: 570px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: auto;
          margin-left: auto;
          position: relative;
          padding: 10px;
          div {
            text-align: center;
          }
          .heading {
            color: #6b5fcc;
            font-family: "Inter", Sans-serif;
            font-size: 16px;
            font-weight: 800;
            text-transform: uppercase;
            line-height: 1.3em;
            letter-spacing: 1px;
            margin: 0px 0px 10px 0px;
          }
          .content {
            color: #ffffff;
            font-family: "Syne", Sans-serif;
            font-size: 48px;
            font-weight: 600;
            line-height: 1.1em;
            letter-spacing: 0px;
            padding: 0px 0px 20px 0px;
            @media (max-width: 640px) {
              font-size: 26px;
            }
          }
          .desc {
            color: #a4a9ac;
            font-family: "DM Sans", Sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.9em;
            padding: 0px 0px 20px 0px;
            @media (max-width: 640px) {
              font-size: 16px;
            }
          }
        }
        .down {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          > div {
            width: 30%;
            @media (max-width: 640px) {
              width: 100%;
            }
          }
          @media (max-width: 640px) {
            flex-direction: column;
          }
          .out-card {
            margin: 20px;
            @media (max-width: 640px) {
              margin: 0 0 20px 0;
            }
            .card {
              padding: 40px;
              .icon {
                width: 100%;
                margin: 0px 0px 20px 0px;
                text-align: center;
                img {
                  width: 80px;
                }
              }
              .heading {
                text-align: center;
                padding: 0px 0px 15px 0px;
                color: #ffffff;
                font-family: "Syne", Sans-serif;
                font-size: 22px;
                font-weight: 600;
                line-height: 1.1em;
                letter-spacing: 0px;
              }
              .content {
                text-align: center;
                color: #a4a9ac;
                font-family: "DM Sans", Sans-serif;
                font-weight: 400;
                line-height: 1.5;
              }
            }
            .secure {
              background-image: radial-gradient(
                at top left,
                #2d2d2d 0%,
                #191919 100%
              );
            }
            .trading {
              background-image: radial-gradient(
                at top left,
                #b6e77e 0%,
                #8bd23a 100%
              );
              .heading,
              .content {
                color: #151515;
              }
            }
          }
        }
      }
    }
  }

  .ld-investing {
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px 80px 0px;
    background-color: #141414;
    background-image: url(https://toka.b-cdn.net/wp-content/uploads/2022/04/gcgtc.png);
    background-position: 1% 95%;
    background-repeat: no-repeat;
    background-size: 150px auto;
    @media (max-width: 640px) {
      background-position: 5% 90%;
      background-size: 60px auto;
    }
    .nav-container {
      display: flex;
      align-items: center;
      @media (max-width: 640px) {
        flex-direction: column;
      }
      .img-group {
        width: 50%;
        @media (max-width: 640px) {
          order: 1;
          width: 100%;
        }
        .line-back {
          display: flex;
          align-content: center;
          align-items: center;
          margin: 10px 0px 0px 10px;
          background-image: url(https://toka.b-cdn.net/wp-content/uploads/2022/04/kgvnrgk.png);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          position: relative;
          @media (max-width: 640px) {
            margin: 0;
          }
          .star {
            position: absolute;
            right: 20%;
            top: 0.7%;
            width: 100px;
            @media (max-width: 640px) {
              right: 0;
              width: 70px;
            }
          }
          .man {
            width: 100%;
          }
        }
      }
      .description {
        z-index: 1;
        width: 50%;
        @media (max-width: 640px) {
          order: 2;
          width: 100%;
          margin: 20px 0;
        }
        .nav-desc {
          margin: 0px 40px 0px 0px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-content: center;
          @media (max-width: 640px) {
            margin: 0;
          }
          .heading {
            margin: 0px 0px 10px 0px;
            color: #b6e77e;
            font-family: "Inter", Sans-serif;
            font-size: 16px;
            font-weight: 800;
            text-transform: uppercase;
            line-height: 1.3em;
            letter-spacing: 1px;
            @media (max-width: 640px) {
              text-align: center;
            }
          }
          .content {
            text-align: left;
            color: #ffffff;
            font-family: "Syne", Sans-serif;
            font-size: 48px;
            font-weight: 600;
            line-height: 1.1em;
            letter-spacing: 0px;
            padding: 0px 0px 20px 0px;
            @media (max-width: 640px) {
              text-align: center;
              font-size: 26px;
            }
          }
          .desc {
            padding: 0px 0px 20px 0px;
            color: #a4a9ac;
            font-family: "DM Sans", Sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.9em;
            @media (max-width: 640px) {
              text-align: center;
              font-size: 16px;
            }
          }
          .link {
            margin: 10px 0px 0px 0px;
            @media (max-width: 640px) {
              text-align: center;
            }
            a {
              font-family: "DM Sans", Sans-serif;
              font-size: 18px;
              font-weight: 700;
              fill: #b6e77e;
              color: #b6e77e;
              background-color: #e0e7fa00;
              border-style: solid;
              border-width: 0px 0px 2px 0px;
              border-color: #b6e77e;
              border-radius: 0px 0px 0px 0px;
              box-shadow: 0px 0px 0px 0px rgba(21, 92, 231, 0.43);
              padding: 0px 0px 7px 0px;
              text-decoration: none;
              .icon {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }

  .ld-download {
    background-color: #141414;
    .nav-container {
      @media (min-width: 640px) {
        padding: 10px;
      }
      .download-card {
        background-image: radial-gradient(
          at top right,
          #2d2d2d 0%,
          #191919 100%
        );
        border-radius: 30px;
        .nav-card {
          position: relative;
          padding: 60px 80px;
          @media (max-width: 640px) {
            padding: 45px 30px;
          }
          .elementor-background-overlay {
            background-image: url("https://toka.b-cdn.net/wp-content/uploads/2022/04/nrgvkg.png");
            background-position: 100% -50px;
            background-repeat: no-repeat;
            background-size: 392px auto;
            opacity: 1;
            transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
            @media (max-width: 640px) {
              background-size: 100px auto;
              background-position: bottom right;
            }
          }
          .content {
            display: flex;
            align-items: center;
            @media (max-width: 640px) {
              flex-direction: column;
            }
            .left {
              padding: 10px;
              display: flex;
              flex-direction: column;
              width: 70%;
              @media (max-width: 640px) {
                padding: 0;
                width: 100%;
              }
              .heading {
                margin: 0 0 20px 0;
                color: #fff;
                font-family: "Space Grotesk", Sans-serif;
                font-size: 45px;
                font-weight: 700;
                line-height: 1.1em;
                letter-spacing: 0px;
                width: 90%;
                @media (max-width: 640px) {
                  font-size: 26px;
                  width: 100%;
                }
                .underline--magical {
                  background-image: linear-gradient(
                    120deg,
                    #6b5fcc 0%,
                    #6b5fcc 100%
                  );
                  background-repeat: no-repeat;
                  background-size: 100% 0.2em;
                  background-position: 0 88%;
                  transition: background-size 0.25s ease-in;
                }
              }
              .content {
                text-align: left;
                color: #a4a9ac;
                font-family: "DM Sans", Sans-serif;
                font-size: 20px;
                font-weight: 500;
                line-height: 1.9em;
                @media (max-width: 640px) {
                  font-size: 16px;
                }
              }
            }
            .right {
              padding: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 10;
              width: 30%;
              @media (max-width: 640px) {
                width: 100%;
              }
              .download-btn {
                font-family: "DM Sans", Sans-serif;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: -0.3px;
                fill: #ffffff;
                color: #ffffff;
                border-radius: 10px;
                box-shadow: 0px 20px 60px 0px
                  rgba(255, 165.99999999999991, 83.99999999999999, 0);
                padding: 15px 35px 15px 35px;
                @media (max-width: 640px) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  .ld-world {
    background-color: #141414;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    padding: 130px 0px 100px 0px;
    position: relative;
    @media (max-width: 640px) {
      padding: 80px 0 60px 0;
    }
    .elementor-background-overlay {
      background-color: transparent;
      background-image: linear-gradient(180deg, #02071000 0%, #141414 60%);
      opacity: 1;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 0;
    }
    .nav-container {
      display: flex;
      align-items: center;
      @media (max-width: 640px) {
        flex-direction: column;
      }
      .left {
        width: 56%;
        @media (max-width: 640px) {
          width: 100%;
        }
        .img-group {
          margin: 0px 30px 0px 0px;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          position: relative;
          @media (max-width: 640px) {
            margin: 0;
          }
          .map {
            width: 100%;
            left: 0;
          }
          .location {
            position: absolute;
            left: 0;
            width: 100%;
            z-index: 1;
          }
        }
      }
      .right {
        width: 44%;
        z-index: 1;
        @media (max-width: 640px) {
          width: 100%;
        }
        .title-group {
          margin: 0px 0px 0px 30px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          @media (max-width: 640px) {
            margin: 20px 0 0 0;
            padding: 0;
          }
          .heading {
            margin: 0px 0px 10px 0px;
            color: #b6e77e;
            font-family: "Inter", Sans-serif;
            font-size: 16px;
            font-weight: 800;
            text-transform: uppercase;
            line-height: 1.3em;
            letter-spacing: 1px;
            @media (max-width: 640px) {
              text-align: center;
            }
          }
          .content {
            padding: 0px 0px 20px 0px;
            color: #ffffff;
            font-family: "Syne", Sans-serif;
            font-size: 48px;
            font-weight: 600;
            line-height: 1.1em;
            letter-spacing: 0px;
            @media (max-width: 640px) {
              text-align: center;
              font-size: 26px;
            }
          }
          .desc {
            padding: 0px 0px 20px 0px;
            color: #a4a9ac;
            font-family: "DM Sans", Sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.9em;
            @media (max-width: 640px) {
              text-align: center;
              font-size: 16px;
            }
          }
          .statistic {
            display: flex;
            width: 100%;
            @media (max-width: 640px) {
              flex-direction: column;
            }
            .left,
            .right {
              display: flex;
              flex-direction: column;
              @media (max-width: 640px) {
                flex-direction: column;
                align-items: center;
                margin: 20px 0;
              }
              .num {
                color: #b6e77e;
                font-family: "Syne", Sans-serif;
                font-size: 64px;
                font-weight: 500;
                line-height: 1.2em;
              }
              .title {
                color: #ffffff;
                font-family: "DM Sans", Sans-serif;
                font-size: 20px;
                font-weight: 500;
                width: 200px;
                max-width: 200px;
                @media (max-width: 640px) {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }

  .ld-guide {
    background-color: #141414;
    background-image: url(https://toka.b-cdn.net/wp-content/uploads/2022/04/kgvnrgk.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 100px 0px 80px 0px;
    position: relative;
    @media (max-width: 640px) {
      padding: 60px 0px 0px 0px;
      background-position: 50% 70%;
    }
    .nav-container {
      display: flex;
      flex-direction: column;
      .top {
        display: flex;
        align-items: center;
        @media (max-width: 640px) {
          flex-direction: column;
        }
        .left {
          display: flex;
          flex-direction: column;
          margin: 0px 40px 0px 0px;
          @media (max-width: 640px) {
            margin: 0;
          }
          .heading {
            margin: 0px 0px 10px 0px;
            color: #f5c06d;
            font-family: "Inter", Sans-serif;
            font-size: 16px;
            font-weight: 800;
            text-transform: uppercase;
            line-height: 1.3em;
            letter-spacing: 1px;
            @media (max-width: 640px) {
              text-align: center;
            }
          }
          .content {
            padding: 0px 0px 20px 0px;
            text-align: left;
            color: #ffffff;
            font-family: "Syne", Sans-serif;
            font-size: 48px;
            font-weight: 600;
            line-height: 1.1em;
            letter-spacing: 0px;
            @media (max-width: 640px) {
              text-align: center;
              font-size: 26px;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          .link {
            margin: 10px 0px 0px 0px;
            @media (max-width: 640px) {
              text-align: center;
            }
            a {
              font-family: "DM Sans", Sans-serif;
              font-size: 18px;
              font-weight: 700;
              fill: #f5c06d;
              color: #f5c06d;
              background-color: #e0e7fa00;
              border-style: solid;
              border-width: 0px 0px 2px 0px;
              border-color: #f5c06d;
              border-radius: 0px 0px 0px 0px;
              box-shadow: 0px 0px 0px 0pxrgba
                (20.999999999999982, 92.00000000000011, 231, 0.43);
              padding: 0px 0px 7px 0px;
              text-decoration: none;
              .icon {
                margin-left: 5px;
              }
            }
          }
          .content {
            color: #a4a9ac;
            font-family: "DM Sans", Sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.9em;
            padding: 0px 0px 20px 0px;
            @media (max-width: 640px) {
              text-align: center;
            }
          }
        }
      }
      .down {
        padding: 60px 0px;
        @media (max-width: 640px) {
          padding: 60px 0 0 0;
        }
        .card-group {
          width: 100%;
          display: flex;
          align-items: flex-start;
          @media (max-width: 640px) {
            flex-direction: column;
          }
          .video {
            width: 50%;
            margin: 10px;
            @media (max-width: 640px) {
              width: 100%;
              margin: 0;
              padding: 10px;
              box-sizing: border-box;
            }
            .image {
              width: 100%;
              padding: 255px 0px 255px 0px;
              background-image: url("./assets/landing/guide.jpg");
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
              border-radius: 30px 30px 30px 30px;
              @media (max-width: 640px) {
                padding: 120px 0px 120px 0px;
              }
              .play {
                width: 100%;
                a {
                  text-decoration: none;
                  .video-button {
                    background-color: #f5c06d;
                    width: 80px;
                    height: 80px;
                    border-radius: 300px;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    position: relative;
                    transition: 0.3s;
                    margin: 0 auto;
                    font-size: 24px;
                    i {
                      color: #ffffff;
                    }
                  }
                }
              }
            }
          }
          .steps {
            width: 50%;
            display: flex;
            flex-direction: column;
            @media (max-width: 640px) {
              width: 100%;
              margin: 0;
              padding: 10px;
              box-sizing: border-box;
            }
            .second,
            .third {
              width: 100%;
              display: flex;
              @media (max-width: 640px) {
                width: 100%;
                box-sizing: border-box;
                flex-direction: column;
              }
              > div {
                width: 50%;
                margin: 10px;
                @media (max-width: 640px) {
                  width: 100%;
                  margin: 0 0 10px 0;
                }
              }
              .card {
                background-image: radial-gradient(
                  at top left,
                  #2d2d2d 0%,
                  #191919 100%
                );
                width: 100%;
                padding: 40px 40px 40px 40px;
                .nav-card {
                  min-height: 205px;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  @media (max-width: 640px) {
                    min-height: auto;
                  }
                  .heading {
                    margin: 0px 0px 8px 0px;
                    color: #ffffff;
                    font-family: "Syne", Sans-serif;
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 1.3em;
                    letter-spacing: 0.2px;
                    @media (max-width: 640px) {
                      text-align: center;
                    }
                  }
                  .content {
                    text-align: left;
                    color: #b8bbbf91;
                    font-family: "DM Sans", Sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 1.5;
                    @media (max-width: 640px) {
                      text-align: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .ld-comment {
    background-color: #141414;
    background-image: url("https://toka.b-cdn.net/wp-content/uploads/2022/04/grb.png");
    background-position: 92% 60%;
    background-repeat: no-repeat;
    background-size: 280px auto;
    overflow: hidden;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    padding: 0px 0px 120px 0px;
    position: relative;
    @media (max-width: 640px) {
      padding: 60px 0;
    }
    .nav-container {
      display: flex;
      .full {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        padding: 10px;
        width: 100%;
        @media (max-width: 640px) {
          padding: 0;
        }
        .top {
          width: 100%;
          max-width: 570px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: auto;
          margin-left: auto;
          position: relative;
          padding: 10px;
          @media (max-width: 640px) {
            padding: 0;
          }
          div {
            text-align: center;
          }
          .heading {
            text-align: center;
            color: #b6e77e;
            font-family: "Inter", Sans-serif;
            font-size: 16px;
            font-weight: 800;
            text-transform: uppercase;
            line-height: 1.3em;
            letter-spacing: 1px;
            margin: 0px 0px 10px 0px;
          }
          .content {
            color: #ffffff;
            font-family: "Syne", Sans-serif;
            font-size: 48px;
            font-weight: 600;
            line-height: 1.1em;
            letter-spacing: 0px;
            padding: 0px 0px 20px 0px;
            @media (max-width: 640px) {
              font-size: 26px;
            }
          }
          .desc {
            color: #a4a9ac;
            font-family: "DM Sans", Sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.9em;
            padding: 0px 0px 20px 0px;
            @media (max-width: 640px) {
              font-size: 16px;
            }
          }
        }
        .down {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          @media (max-width: 640px) {
            flex-direction: column;
          }
          > div {
            width: 33%;
            @media (max-width: 640px) {
              width: 100%;
            }
          }
          .out-card {
            margin: 25px;
            @media (max-width: 640px) {
              margin: 10px;
            }
            .card {
              padding: 35px;
              min-height: 370px;
              @media (max-width: 640px) {
                min-height: auto;
              }
              .star {
                margin-bottom: 40px;
                text-align: left;
                .elementor-star-rating {
                  color: #b6e77e;
                  i {
                    margin-right: 6px;
                  }
                }
              }
              .content {
                margin-bottom: 20px;
                text-align: left;
                color: #b3b4b7;
                font-family: "DM Sans", Sans-serif;
                font-size: 22px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 1.5;
                @media (max-width: 640px) {
                  font-size: 18px;
                }
              }
              .user {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .name {
                  width: 75%;
                  display: flex;
                  flex-direction: column;
                  @media (max-width: 640px) {
                    width: 70%;
                  }
                  .nick {
                    padding: 0px 0px 10px 0px;
                    color: #ffffff;
                    font-family: "Syne", Sans-serif;
                    font-size: 20px;
                    font-weight: 600;
                    letter-spacing: 0px;
                  }
                  .position {
                    color: #676768;
                    font-family: "Syne", Sans-serif;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0px;
                  }
                }
                .avatar {
                  width: 25%;
                  text-align: right;
                  @media (max-width: 640px) {
                    width: 30%;
                  }
                  img {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    border-radius: 100%;
                  }
                }
              }
            }
            .secure {
              background-image: radial-gradient(
                at top left,
                #2d2d2d 0%,
                #191919 100%
              );
            }
            .trading {
              background-image: radial-gradient(
                at top left,
                #b6e77e 0%,
                #8bd23a 100%
              );
              .content {
                color: #141414;
              }
              .star {
                .elementor-star-rating {
                  color: #141414;
                }
              }
              .user {
                .name {
                  .nick {
                    color: #141414;
                  }
                  .position {
                    color: #141414a8;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
